import { Component } from '@angular/core';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { SearchBarComponent } from '../../../shared/component/search-bar/search-bar.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MenuComponent,
    SearchBarComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {

}
