import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskDirective } from 'ngx-mask';
import { Checkout } from '../../../../shared/services/models/orchestrator-checkout/checkout.model';
import { BusinessService } from '../../../../shared/services/API/business-user/business.service';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { BusinessResponse } from '../../../../shared/services/responses/business-user/business.response';
import { SendToClientRequest } from '../../../../shared/services/requests/checkout/send-to-client.request';
import { CheckoutService } from '../../../../shared/services/API/checkout/checkout.service';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';
import { Router } from "@angular/router";

@Component({
  selector: 'modal-business-store-owner-infos',
  standalone: true,
  imports: [ 
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective],
  templateUrl: './modal-business-store-owner-infos.html',
  styleUrl: './modal-business-store-owner-infos.css'
})
export class ModalBusinessStoreOwnerInfosComponent implements OnInit{
  
  constructor(private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalBusinessStoreOwnerInfosComponent>,
    private businessService:BusinessService,
    private alertService:AlertService,
    private checkoutService:CheckoutService,
    private router: Router){
      this._checkout = data.checkout 
  }
  
  public model: FormGroup;
  public isLoading:boolean = false;
  public _checkout:Checkout;
  public _businessResponse:BusinessResponse;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      businessStoreOwnerName: ['', [Validators.required]],
      businessStoreOwnerEmail: ['', [Validators.required, Validators.email]],
    });

    this.populate();
  }

  populate(){
    this.businessService.GetBusiness(this._checkout.idBusinessStore).subscribe({
      next:(businessResponse:BusinessResponse)=>{
        if (businessResponse.isError) {
          this.alertService.show("Erro inesperado", businessResponse.errorDescription, AlertType.error);
          return;
        }

        this._businessResponse = businessResponse;

        this.model.get('businessStoreOwnerName')?.setValue(businessResponse.business.ownerName);
        this.model.get('businessStoreOwnerEmail')?.setValue(businessResponse.business.businessEmail);
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  
  }

  post(){
    let sendToClientRequest:SendToClientRequest = new SendToClientRequest();
    
    sendToClientRequest.clientName = this.model.get('businessStoreOwnerName')?.value;
    sendToClientRequest.clientEmail = this.model.get('businessStoreOwnerEmail')?.value;
    sendToClientRequest.idCheckout = this._checkout.idCheckout;

    this.isLoading = true;
    this.checkoutService.sendToClient(sendToClientRequest).subscribe({
      next:(returnStruct:ReturnStruct)=>{
        this.isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro inesperado", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.dialogRef.close();
        this.router.navigate(['/consult/search/done/' + sendToClientRequest.idCheckout]);
      },
      error: (error)=>{
        this.isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });

  }
  
}
