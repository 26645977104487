<app-action-user [showActionUser]="showActionUser" (showActionUserFalse)="clickShowActionUser(false)"></app-action-user>
<app-action-nav [showActionNav]="showActionNav" (showActionNavFalse)="clickShowActionNav(false)"></app-action-nav>
<header>
    <div class="container">
        <div class="logo"> 
            <a routerLink="/consult/home"> 
                <img class="logo-auth" src="assets/images/fashion.app2one.png">
            </a>
            
        </div>
        <div class="action">
            <a><span class="material-symbols-outlined">notifications</span></a>
            <a (click)="clickShowActionNav(null)"><span class="material-symbols-outlined">apps</span></a>
            <a (click)="clickShowActionUser(null)"><span class="material-symbols-outlined">account_circle</span></a>
        </div>
    </div>
</header>

<div class="space-header"></div>