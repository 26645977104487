import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.responde';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-action-user',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './action-user.component.html',
  styleUrl: './action-user.component.css'
})
export class ActionUserComponent implements OnInit{
  @Input() showActionUser:boolean;
  @Output() showActionUserFalse =  new EventEmitter<number>()
  public userLoginResponse:UserLoginResponse;

  constructor(private authService: AuthService){

  }

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos()
  }

  clickContainerOut(){
    this.showActionUserFalse.emit();
  }

  clickLogout(){
    this.authService.logout();
  }
}
