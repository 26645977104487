
@if(productCheckoutStruct != undefined && productCheckoutStruct != null){
    <div class="checkout-item">
        <div class="row">
            <div class="col-3">
                <img class="image-item" src="assets/images/image-item-teste.png" />
            </div>
            <div class="col-7">
                <label class="business-iten-price">{{productCheckoutStruct.productCheckout.totalPrice | currency: "BRL"}}</label>
                <label class="business-name">{{businessFactoryName}}</label>
                <label class="business-iten-name">{{productCheckoutStruct.productCheckout.productName}}</label>
                <label class="business-iten-count">{{productCheckoutStruct.productCheckout.totalQuantity}} peças</label>
                <label class="business-iten-count-description">2 cores escolhidas em 3 grades de tamanho</label>
            </div>
            <div class="col-2 text-center">
                <button mat-mini-fab class="fab-action red" aria-label="Deletar essa peça" (click)="clickDeleteCheckoutItem(productCheckoutStruct.productCheckout.idProductCheckout, productCheckoutStruct.productCheckout.idCheckout)">
                    @if(productCheckoutStruct.isLoading){
                        <mat-spinner [diameter]="15" class="mat-spinner-btn"></mat-spinner>
                    }@else {
                        <mat-icon>delete</mat-icon>
                    }
                </button>
                <button mat-mini-fab class="fab-action orange" aria-label="Editar essa peça" >
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </div>
}
