import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductSearchService } from '../../services/API/inventory/product-search.service';
import { AlertService, AlertType } from '../../services/alert.service';
import { Category } from '../../services/models/inventory/category.model';
import { Collection } from '../../services/models/inventory/collection.model';
import { ProductSearchLookupResponse } from '../../services/responses/inventory/product-search-lookup.response';

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatButtonModule, ReactiveFormsModule],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.css'
})
export class SearchBarComponent  implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private productSearchService:ProductSearchService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute
  ){

  }

  public model: FormGroup;
  public _listCategoryModel: Category[] = [];
  public _listCollectionModel: Collection[] = [];
  @Input() isShowMobile:boolean;
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      seachText: [''],
      listIdBusiness: [],
      listIdCategory: [],
      listIdCollection: []
    });



    this.populateModelWithUrlValues();
    this.populateLookupsInventory();
  }

  search(){
    let redirect:string = '/consult/search';

    let seachText:string =  this.model.get("seachText")?.value
    let listIdBusiness:number[] = this.model.get("listIdBusiness")?.value;
    let listIdCategory:number[] = this.model.get("listIdCategory")?.value;
    let listIdCollection:number[] = this.model.get("listIdCollection")?.value;
    let isImmediateDelivery:string = this.model.get("isImmediateDelivery")?.value;
    let idBusinessStore = this.activatedRoute.snapshot.params["idBusinessStore"];;

    let queryParams = {} as any;
    
    if(seachText){
      queryParams.seachText = seachText;
    }

    if(listIdBusiness){
      queryParams.listIdBusiness = listIdBusiness?.join(',');
    }

    if(listIdCategory){
      queryParams.listIdCategory = listIdCategory?.join(',');
    }

    if(listIdCollection){
      queryParams.listIdCollection = listIdCollection?.join(',');
    }

    if(idBusinessStore){
      queryParams.idBusinessStore = idBusinessStore;
    }

    this.router.navigate([redirect, queryParams]).then(() => {
      window.location.reload();
    });
  }

  populateLookupsInventory(){
    this.productSearchService.lookup().subscribe({
      next: (productSearchLookupResponse:ProductSearchLookupResponse)=>{
        if (productSearchLookupResponse.isError) {
          this.alertService.show("Erro inesperado", productSearchLookupResponse.errorDescription, AlertType.error);
          return;
        }

        this._listCategoryModel = productSearchLookupResponse.listCategory;
        this._listCollectionModel = productSearchLookupResponse.listCollection;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
    }})
  }

  populateModelWithUrlValues(){
    let searchTextUrl:string = this.activatedRoute.snapshot.params["seachText"];
    let listIdBusinessUrl:string = this.activatedRoute.snapshot.params["listIdBusiness"];
    let listIdCategoryUrl:string = this.activatedRoute.snapshot.params["listIdCategory"];
    let listIdCollectionUrl:string = this.activatedRoute.snapshot.params["listIdCollection"];

    if(listIdBusinessUrl){
      let aux = listIdBusinessUrl.split(",");
      this.model.get("listIdBusiness")?.setValue(aux);
    }

    if(listIdCategoryUrl){
      let aux = listIdCategoryUrl.split(",");
      this.model.get("listIdCategory")?.setValue(aux);
    }

    if(listIdCollectionUrl){
      let aux = listIdCollectionUrl.split(",");
      this.model.get("listIdCollection")?.setValue(aux);
    }

    this.model.get("seachText")?.setValue(searchTextUrl); 
  }
}
