@if(productCheckoutStruct == undefined || productCheckoutStruct == null) {
    <div class="checkout-item">
        <div class="row">
            <div class="col-3">
                <app-loading-box [height]="'64'" [width]="'64'"></app-loading-box>
            </div>
            <div class="col-9">
                <app-loading-box [height]="'15'" [width]="'120'"></app-loading-box>
                <app-loading-box [height]="'15'" [width]="'90'" [marginTop]="'10'"></app-loading-box>
                <app-loading-box [height]="'15'" [width]="'50'" [marginTop]="'15'"></app-loading-box>
            </div>
        </div>
    </div>
}


@if(productCheckoutStruct != undefined && productCheckoutStruct != null){
    <div class="checkout-item">
        <div class="row">
            <div class="col-3">
                <img class="image-item" src="{{productCheckoutStruct.productImageUrl}}" />
            </div>
            <div class="col-6">
                <label class="business-name">{{businessFactoryName}}</label>
                <label class="business-iten-name">{{productCheckoutStruct.productCheckout.productName}}</label>
                <label class="business-iten-count">{{productCheckoutStruct.productCheckout.totalQuantity}} peças</label>
            </div>
            <div class="col-3 text-center">
                <button mat-mini-fab class="fab-action red" aria-label="Deletar essa peça" (click)="clickDeleteCheckoutItem(productCheckoutStruct.productCheckout.idProductCheckout, productCheckoutStruct.productCheckout.idCheckout)">
                    @if(productCheckoutStruct.isLoading){
                        <mat-spinner [diameter]="15" class="mat-spinner-btn"></mat-spinner>
                    }@else {
                        <mat-icon>delete</mat-icon>
                    }
                        
                </button>
                <button mat-mini-fab class="fab-action orange" aria-label="Editar essa peça" (click)="clickEditItem(productCheckoutStruct.productCheckout.idProduct, productCheckoutStruct.productCheckout.idCheckout)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </div>
}
