import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConsultorStore } from '../../../../shared/services/API/orchestator-checkout/consultor-store.service';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { ConsultorStoreResponse } from '../../../../shared/services/responses/orchestrator-checkout/consultor-store.response';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../shared/services/API/business-user/user.service';
import { InviteBusinessStoreRequest } from '../../../../shared/services/requests/business-user/invite-business-store.request';
import { InviteBusinessStoreResponse } from '../../../../shared/services/responses/business-user/invite-business-store.response';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-modal-select-client',
  standalone: true,
  imports: [    
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatProgressSpinnerModule,
    MatIconModule,
    MatProgressBarModule, 
    CommonModule,
    NgxMaskDirective],
  providers:[
    provideNgxMask()
  ],
  templateUrl: './modal-invite-business-store.component.html',
  styleUrl: './modal-invite-business-store.component.css'
})
export class ModalInviteBusinessStoreComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, 
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ModalInviteBusinessStoreComponent>,
    private userService:UserService){

  }

  public model: FormGroup;
  public _isLoading = false;


  ngOnInit(): void {
    this.model = this.formBuilder.group({
      userBusinessName: ['', [Validators.required]],
      userEmail: ['', [Validators.required, Validators.email]],
      userName: ['', [Validators.required]],
      userPhoneNumber: ['', [Validators.required]],
    });
  }

  inviteBusinessStore(){
    if(this.model.invalid)
      return;

    if(this._isLoading)
      return;

    let inviteBusinessStoreRequest:InviteBusinessStoreRequest = new InviteBusinessStoreRequest(); 
    inviteBusinessStoreRequest.userBusinessName = this.model.get('userBusinessName')?.value;
    inviteBusinessStoreRequest.userEmail = this.model.get('userEmail')?.value;
    inviteBusinessStoreRequest.userName = this.model.get('userName')?.value;
    inviteBusinessStoreRequest.userPhoneNumber = this.model.get('userPhoneNumber')?.value;

    this._isLoading = true;
    this.userService.inviteBusinessStore(inviteBusinessStoreRequest).subscribe({
      next:(inviteBusinessStoreResponse:InviteBusinessStoreResponse)=>{
        this._isLoading = false;
        if (inviteBusinessStoreResponse.isError) {
          this.alertService.show("Erro inesperado", inviteBusinessStoreResponse.errorDescription, AlertType.error);
          return;
        }

        let data = {
          openSelectBusinessStore: false,
          idBusinessStore:inviteBusinessStoreResponse.idBusinessSotre
        };

        this.dialogRef.close(data);
        this.alertService.show("Sucesso", "Convite enviado para o lojsta.", AlertType.success);
      },
      error: (error)=>{
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  selectBusinessStore(idBusinessStore:number){
    let data = {
      openSelectBusinessStore: false,
      idBusinessStore:idBusinessStore
    };
    this.dialogRef.close(data);
  }

  openSelectBusinessSotre(){
    let data = {
      openSelectBusinessStore:true,
      idBusinessStore:null
    };
    this.dialogRef.close(data);
  }
}
