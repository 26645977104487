import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { CommonService } from './common-service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilService extends CommonService {

  constructor( private httpClient: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  public getIP(): Observable<IPReturn> {

    return this.httpClient.get<IPReturn>("https://api.ipify.org/?format=json")
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAddressByCEP(cep:string): Observable<CEPCorreios> {
    
    return this.httpClient.get<CEPCorreios>('https://viacep.com.br/ws/'+cep+'/json/')
    .pipe( 
      catchError(this.handleError)
    )
  }
}

export class IPReturn {
  public ip: string
}

export class CEPCorreios{
  public cep:string
  public logradouro:string
  public complemento:string
  public bairro:string
  public localidade:string
  public uf:string
  public ibge:string
}
