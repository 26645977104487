import { booleanAttribute, Component, OnInit } from '@angular/core';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { SearchBarComponent } from '../../../shared/component/search-bar/search-bar.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SearchItemComponent } from "./search-item/search-item.component";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddItemComponent } from './modal-add-item/modal-add-item.component';
import { ProductSearchService } from '../../../shared/services/API/inventory/product-search.service';
import { ProductSearchRequest } from '../../../shared/services/requests/inventory/product-search.request';
import { ProductSearchResponse } from '../../../shared/services/responses/inventory/product-search.response';
import { CheckoutService } from '../../../shared/services/API/orchestator-checkout/checkout.service';
import { CheckoutResponse } from '../../../shared/services/responses/orchestrator-checkout/checkout.response';
import { ModalSelectBusinessStoreComponent } from './modal-select-business-store/modal-select-business-store.component';
import { ModalInviteBusinessStoreComponent } from './modal-invite-business-store/modal-invite-business-store.component';
import { BusinessService } from '../../../shared/services/API/business-user/business.service';
import { BusinessResponse } from '../../../shared/services/responses/business-user/business.response';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    MenuComponent,
    SearchBarComponent,
    CheckoutComponent,
    SearchItemComponent,
    MatButtonModule,
    MatIconModule
],
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})
export class SearchComponent implements OnInit{

  constructor(    
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private productSearchService:ProductSearchService,
    private checkoutService:CheckoutService,
    private router: Router,
    public businessService:BusinessService){

  }

  public _idBusinessStore:number = -1;
  public _isBusinessStoreSelected:boolean = false;
  public _productSearchResponse:ProductSearchResponse;
  public _checkoutResponse:CheckoutResponse;
  public _businessStoreResponse:BusinessResponse;
  public _page = 0;
   

  ngOnInit(): void {
    let idBusinessStoreUrl:string = this.activatedRoute.snapshot.params["idBusinessStore"];

    if(idBusinessStoreUrl){
      this._idBusinessStore = Number(idBusinessStoreUrl);
      this.populateUserBusinessStore();
      this.populateListCheckout()
    }

    this.search();     
  }

  openDialogAddItem(idProduct:number): void {
    if (this._isBusinessStoreSelected == false){
      this.openDialogSelectClient();
      return;
    }

    const dialogRef = this.dialog.open(ModalAddItemComponent, {      
      data: {
        idProduct: idProduct,
        idBusinessStore: this._idBusinessStore,
        idCheckout: this._checkoutResponse?.checkout?.idCheckout,
        disable:false
      },
      panelClass: 'dialog-add-item'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.populateListCheckout();
    });
  }

  openDialogSelectClient(): void {
    const dialogRef = this.dialog.open(ModalSelectBusinessStoreComponent, {
      data: {},
      panelClass: 'dialog-search-add-client'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){

        if(result.openInviteBusinessSotre == false){
          this.redirectWithSearchParametersURL('/consult/search',{idBusinessStore: result.idBusinessStore});
          return;
        }

        if(result.openInviteBusinessSotre == true){
          this.openDialogIntiveBusinessSotre();
        }
      }
    });
  }

  openDialogIntiveBusinessSotre(){
    const dialogRef = this.dialog.open(ModalInviteBusinessStoreComponent, {
      data: {},
      panelClass: 'dialog-search-invite-client'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){

        if(result.openSelectBusinessStore == false){
          this.redirectWithSearchParametersURL('/consult/search',{idBusinessStore: result.idBusinessStore});
          return;
        }

        if(result.openSelectBusinessStore == true){
          this.openDialogSelectClient();
        }
      }
    });
  }

  redirectWithSearchParametersURL(redirect:string, any:any){

    let seachText:string =  this.activatedRoute.snapshot.params["seachText"];
    let listIdBusiness:number[] = this.activatedRoute.snapshot.params["listIdBusiness"];
    let listIdCategory:number[] = this.activatedRoute.snapshot.params["listIdCategory"];
    let listIdcolection:number[] = this.activatedRoute.snapshot.params["listIdCollection"];
    let isImmediateDelivery:string = this.activatedRoute.snapshot.params["isImmediateDelivery"];
    let idBusinessStore:number[] = this.activatedRoute.snapshot.params["idBusinessStore"];

    if(any == undefined)
      any =  {} as any;

    let queryParams = any;
    
    if(seachText){
      queryParams.seachText = seachText;
    }

    if(listIdBusiness){
      queryParams.listIdBusiness = listIdBusiness?.join(',');
    }

    if(listIdCategory){
      queryParams.listIdCategory = listIdCategory?.join(',');
    }

    if(listIdcolection){
      queryParams.listIdcolection = listIdcolection?.join(',');
    }
    
    if(idBusinessStore){
      queryParams.idBusinessStore = idBusinessStore;
    }

    this.router.navigate([redirect, queryParams]).then(() => {
      window.location.reload();
    });
    
  }

  search(){
    let productSearchReqeust:ProductSearchRequest = new ProductSearchRequest();

    let seachTextURL:string =  this.activatedRoute.snapshot.params["seachText"];
    let listIdBusinessURL:string = this.activatedRoute.snapshot.params["listIdBusiness"];
    let listIdCategoryURL:string = this.activatedRoute.snapshot.params["listIdCategory"];
    let listIdCollectionURL:string = this.activatedRoute.snapshot.params["listIdCollection"];
    let isImmediateDeliveryURL:string = this.activatedRoute.snapshot.params["isImmediateDelivery"];
    let idBusinessStoreURL:string = this.activatedRoute.snapshot.params["idBusinessStore"];

    productSearchReqeust.searchText = seachTextURL;
    productSearchReqeust.page = this._page;

    if(listIdBusinessURL)
      productSearchReqeust.listIdBusiness = listIdBusinessURL.split(',').map(Number);

    if(listIdCategoryURL)
      productSearchReqeust.listIdCategory = listIdCategoryURL.split(',').map(Number);

    if(listIdCollectionURL)
      productSearchReqeust.listIdCollection = listIdCollectionURL.split(',').map(Number);

    if(isImmediateDeliveryURL)
      productSearchReqeust.isImmediateDelivery = (isImmediateDeliveryURL =="true");

    this.productSearchService.productSearch(productSearchReqeust).subscribe({
      next:(productSearchResponse:ProductSearchResponse)=>{
        if (productSearchResponse.isError) {
          this.alertService.show("Erro inesperado", productSearchResponse.errorDescription, AlertType.error);
          return;
        }

        this._productSearchResponse = productSearchResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  populateListCheckout(){
    this.checkoutService.listCheckout(this._idBusinessStore).subscribe({

      next:(checkoutResponse:CheckoutResponse)=>{
        if (checkoutResponse.isError) {
          this.alertService.show("Erro inesperado", checkoutResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutResponse = checkoutResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  populateUserBusinessStore(){
    this.businessService.GetBusiness(this._idBusinessStore).subscribe({

      next:(businessResponse:BusinessResponse)=>{
        if (businessResponse.isError) {
          this.alertService.show("Erro inesperado", businessResponse.errorDescription, AlertType.error);
          return;
        }
        
        this._isBusinessStoreSelected = true;
        this._businessStoreResponse = businessResponse;
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickDeleteCheckoutItem(event:any){
    this.checkoutService.delteItemCheckout(event.idProductCheckout, event.idCheckout).subscribe({
      next:(checkoutResponse:CheckoutResponse)=>{
        if (checkoutResponse.isError) {
          this.alertService.show("Erro inesperado", checkoutResponse.errorDescription, AlertType.error);
          return;
        }

        this._checkoutResponse = checkoutResponse;
        this.alertService.show("Sucesso", 'Produto removido do seu pedido com sucesso', AlertType.success);
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  clickEditCheckoutItem(event:any){
    this.openDialogAddItem(event.idProductCheckout);
  }

  clickCloseCheckout(){
    this.redirectWithSearchParametersURL('consult/search/resume', undefined);
  }
}
