import { CheckoutProductStruct } from "../../structs/orchestrator-checkout/checkout-product.struct";

export class CheckoutAddressRequest {
  public idCheckout:number;
  public zipcode:string;
  public country:string;
  public state:string;
  public city:string;
  public street:string;
  public addressNumber:string;
  public neighborhood:string;
  public complement:string;
  public latitude:string;
  public longitude:string;
}
