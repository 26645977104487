import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ProductCheckoutStruct } from '../../../../../shared/services/structs/orchestrator-checkout/product-checkout.struct';
import { LoadingBoxComponent } from "../../../../../shared/component/loading-box/loading-box.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-checkout-item',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, LoadingBoxComponent, MatProgressSpinnerModule],
  templateUrl: './checkout-item.component.html',
  styleUrl: './checkout-item.component.css'
})
export class CheckoutItemComponent {
  @Input() productCheckoutStruct:ProductCheckoutStruct;
  @Input() businessFactoryName:string;
  @Output() deleteCheckoutItem =  new EventEmitter<any>()
  @Output() editCheckoutItem =  new EventEmitter<any>()

  clickDeleteCheckoutItem(idProductCheckout:number, idCheckout:number){
    if(this.productCheckoutStruct.isLoading)
      return;

    this.productCheckoutStruct.isLoading = true;
    
    let value = {
      idProductCheckout: idProductCheckout,
      idCheckout: idCheckout
    }
      
    this.deleteCheckoutItem.emit(value);
  }

  clickEditItem(idProductCheckout:number, idCheckout:number){

    if(this.productCheckoutStruct.isLoading)
      return;
    
    let value = {
      idProductCheckout: idProductCheckout,
      idCheckout: idCheckout
    }

    this.editCheckoutItem.emit(value);
  }
}
