@if(showActionNav){
    <div class="container-item">
       <a class="btn-nav">
            <div class="icon" routerLink="/consult/home">
                <span class="material-symbols-outlined">home</span>
            </div>
            <label>Home</label>
       </a>
       <a class="btn-nav" routerLink="/consult/checkout">
            <div class="icon">
                <span class="material-symbols-outlined">shopping_bag</span>
            </div>
            <label>Pedidos</label>
       </a>
       <a class="btn-nav">
            <div class="icon">
                <span class="material-symbols-outlined">wallet</span>
            </div>
            <label>Carteira</label>
        </a>

        <a class="btn-nav">
            <div class="icon">
                <span class="material-symbols-outlined">storefront</span>
            </div>
            <label>Lojistas</label>
        </a>
        <a class="btn-nav">
            <div class="icon">
                <span class="material-symbols-outlined">domain</span>
            </div>
            <label>Confecções</label>
        </a>
    </div>
    <div class="container-out" (click)="clickContainerOut()"></div>
}
