import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ProductResponseStruct } from '../../../../shared/services/structs/inventory/product-response.struct';
import { CommonModule } from '@angular/common';
import { LoadingBoxComponent } from "../../../../shared/component/loading-box/loading-box.component";

@Component({
  selector: 'app-search-item',
  standalone: true,
  imports: [
    MatButtonModule,
    CommonModule,
    LoadingBoxComponent
],
  templateUrl: './search-item.component.html',
  styleUrl: './search-item.component.css'
})
export class SearchItemComponent {
  @Output() clickAddItem =  new EventEmitter<number>()
  @Input() productResponseStruct:ProductResponseStruct;

  constructor(){

  }
  
  btnClickAddItem(idProduct:number){
    this.clickAddItem.emit(idProduct);
  }
}
