
export class ProductSearchRequest {
  public isImmediateDelivery:boolean;
  public searchText:string;
  public listIdCollection:number[];
  public listIdBusiness:number[];
  public listIdCategory:number[];
  public page:number;
  public rangeStartPrice:number;
  public rangeEndPrice:number;
}
