import { Component, OnInit } from '@angular/core';
import { ActionUserComponent } from "./action-user/action-user.component";
import { ActionNavComponent } from './action-nav/action-nav.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    ActionUserComponent,
    ActionNavComponent,
    CommonModule,
    RouterModule
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent implements OnInit {
  public showActionUser:boolean = false;
  public showActionNav:boolean = false;
  
  constructor(){

  }

  ngOnInit(): void {
   
  }

  clickShowActionUser(action:any){
    if(action == null){
      this.showActionUser = !this.showActionUser;
      return;
    }

    this.showActionUser = action;     
  }

  clickShowActionNav(action:any){
    if(action == null){
      this.showActionNav = !this.showActionNav;
      return;
    }

    this.showActionNav = action;     
  }
}
