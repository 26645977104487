<h2 mat-dialog-title>Dados do lojista para enviar o pedido</h2>

<form class="form" [formGroup]="model"  (ngSubmit)="post()">
    <mat-dialog-content>
        <p>Confira o nome e email do lojista para enviarmos o pedido para o email dele.</p>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Nome do lojista</mat-label>
                    <input matInput formControlName="businessStoreOwnerName" type="text">
                    @if (model.get('businessStoreOwnerName')?.invalid) {
                        <mat-error>Informe o nome do lojista</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>E-mail do lojista para receber o link</mat-label>
                    <input matInput formControlName="businessStoreOwnerEmail" type="text">
                    @if (model.get('businessStoreOwnerEmail')?.invalid) {
                        <mat-error>Informe o e-mail do lojista</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>
        
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button class="btn-primary btn-send-link" type="submit">
            @if (isLoading == true) {
                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
            }
            @else{
                Enviar para o lojista
                <span class="material-symbols-outlined">arrow_forward</span>
            }
        </button>
    </mat-dialog-actions>
</form>