<div class="container">
    <div class="seach-bar">
        <form class="form" [formGroup]="model"  (ngSubmit)="search()">
            <mat-form-field appearance="fill" class="mat-form-field-text">
                <mat-label>O que procura?</mat-label>
                <input matInput placeholder="O que procura?" formControlName="seachText">
            </mat-form-field>
            <mat-form-field class="mat-form-field-business">
                <mat-label>Marca</mat-label>
                <mat-select matNativeControl formControlName="listIdBusiness" multiple>
                    <mat-option value="">Todas</mat-option>
                    <mat-option value="1">Teste</mat-option>    
                    <mat-option value="2">Teste 2</mat-option>             
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Categoria</mat-label>
                <mat-select matNativeControl  formControlName="listIdCategory" multiple>
                    <mat-option value="">Todas</mat-option>
                    @for (item of _listCategoryModel; track $index) {
                        <mat-option value="{{item.idCategory}}">{{item.categoryName}}</mat-option>    
                    }   
                </mat-select>
            </mat-form-field>
        
            <mat-form-field class="mat-form-colection">
                <mat-label>Coleção</mat-label>
                <mat-select matNativeControl formControlName="listIdCollection" multiple>
                    <mat-option value="">Todas</mat-option>
                    @for (item of _listCollectionModel; track $index) {
                        <mat-option value="{{item.idCollection}}">{{item.collectionName}}</mat-option>    
                    }        
                </mat-select>
            </mat-form-field>
        
            <button mat-flat-button type="submit" class="btn-primary btn-search">
                Pesquisar
            </button>
        </form>
    </div>
    

</div>