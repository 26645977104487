<h2 mat-dialog-title>Disconto da fabrica {{_businessCheckoutStruct.productBusinessName}}</h2>

<form class="form" [formGroup]="model"  (ngSubmit)="post()">
    <mat-dialog-content>
        <p>IMPORTANTE: Lembre-se de antes de aplicar um desconto confirmar com a fabrica para garantir que esta certo e evitar cancelamento do pedido</p>
        <p>Valor atual do pedido na fabrica {{_businessCheckoutStruct.productBusinessName}} <span style="font-weight: 600;">{{_businessCheckoutStruct.totalPrice | currency: 'BRL'}}</span> </p>
        <div class="row"> 
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Valor do disconto</mat-label>
                    <input matInput formControlName="discountValue" class="example-right-align" placeholder="0" type="number" >
                    <span matTextPrefix>R$&nbsp;</span>
                    <span matTextSuffix>.00</span>
                    @if (model.get('discountValue')?.invalid) {
                        <mat-error>Informe o valor do disconto</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>motivo</mat-label>
                    <textarea matInput formControlName="discountMotive" type="text" rows="5"></textarea>
                    @if (model.get('discountMotive')?.invalid) {
                        <mat-error>Informe o motivo do disconto</mat-error> 
                    }
                </mat-form-field>
            </div>
            
        </div>
        
    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="submit" mat-flat-button class="btn-primary button-big">
            @if (isLoading == true) {
                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
            }
            @else{
                Salvar
            }
        </button>
    </mat-dialog-actions>
</form>