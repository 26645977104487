import { Routes } from '@angular/router';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { HomeComponent } from './consult/pages/home/home.component';
import { AuthGuard } from './auth-guard.service';
import { SearchComponent } from './consult/pages/search/search.component';
import { SearchResumeCheckoutComponent } from './consult/pages/search-resume-checkout/search-resume-checkout.component';
import { PasswordRecoveryComponent } from './auth/pages/password-recovery/password-recovery.component';
import { PassordChangeComponent } from './auth/pages/passord-change/passord-change.component';
import { SearchCheckoutDoneComponent } from './consult/pages/search-checkout-done/search-checkout-done.component';
import { CheckoutListComponent } from './consult/pages/checkout-list/checkout-list.component';
import { CheckoutDetailsComponent } from './consult/pages/checkout-details/checkout-details.component';

export const routes: Routes = [
    { path: '', component: AuthComponent, pathMatch: 'full' },
    { path: 'password-recovery', component: PasswordRecoveryComponent },
    { path: 'change-password/:token', component: PassordChangeComponent },
    { path: 'consult/home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'consult/search', component: SearchComponent, canActivate: [AuthGuard] },
    { path: 'consult/search/resume', component: SearchResumeCheckoutComponent, canActivate: [AuthGuard] },
    { path: 'consult/search/done/:idCheckout', component: SearchCheckoutDoneComponent, canActivate: [AuthGuard] },
    { path: 'consult/checkout', component: CheckoutListComponent, canActivate: [AuthGuard] },
    { path: 'consult/checkout-details/:idCheckout', component: CheckoutDetailsComponent, canActivate: [AuthGuard] },
    
];
