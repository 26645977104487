import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { MyCheckoutSearchResponse } from '../../responses/checkout/my-checkout-search.response';
import { SearchCheckoutRequest } from '../../requests/checkout/search-checkout.request';

@Injectable({
  providedIn: 'root'
})
export class MyCheckoutService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public search(searchCheckoutRequest:SearchCheckoutRequest): Observable<MyCheckoutSearchResponse> {
    return this.httpClient.post<MyCheckoutSearchResponse>(environment.urlApiCheckout + "MyCheckout/Search", searchCheckoutRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
