import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout-status',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './checkout-status.component.html',
  styleUrl: './checkout-status.component.css'
})
export class CheckoutStatusComponent  implements OnInit{
  @Input() statusName:string;
  @Input() statusDetails:string;
  @Input() idStatusCheckoout:number;
  @Input() dateTimeInclusionStatus:Date | undefined;
  @Input() isActive:boolean;
  @Input() currentStatus:boolean;

  ngOnInit(): void {

  }

}
