import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ProductSearchLookupResponse } from '../../responses/inventory/product-search-lookup.response';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ProductSearchRequest } from '../../requests/inventory/product-search.request';
import { ProductSearchResponse } from '../../responses/inventory/product-search.response';

@Injectable({
  providedIn: 'root'
})
export class ProductSearchService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public lookup(): Observable<ProductSearchLookupResponse> {
    return this.httpClient.get<ProductSearchLookupResponse>(environment.urlApiInventory + "ProductSearch/lookup", this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public productSearch(productSearchReqeust:ProductSearchRequest): Observable<ProductSearchResponse> {
    return this.httpClient.post<ProductSearchResponse>(environment.urlApiInventory + "ProductSearch", productSearchReqeust, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
