import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuComponent } from '../../../shared/component/menu/menu.component';


@Component({
  selector: 'app-search-checkout-done',
  standalone: true,
  imports: [MenuComponent],
  templateUrl: './search-checkout-done.component.html',
  styleUrl: './search-checkout-done.component.css'
})
export class SearchCheckoutDoneComponent implements OnInit{

  constructor(private activatedRoute:ActivatedRoute){
    this.idCheckout = Number(this.activatedRoute.snapshot.paramMap.get('idCheckout'));
  }

  public idCheckout:number;

  ngOnInit(): void {
   
  }
}
