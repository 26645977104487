<div  mat-dialog-title>
    <h2 class="title-default">Convide seu novo lojista</h2> 
</div>

<mat-dialog-content>
    <div class="invite-client">
        <form class="form" [formGroup]="model"  (ngSubmit)="inviteBusinessStore()">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome da loja</mat-label>
                        <input matInput formControlName="userBusinessName" type="text" focus>
                        @if (model.get('userBusinessName')?.invalid) {
                            <mat-error>Informe o nome da loja</mat-error>
                        }
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Nome do cliente</mat-label>
                        <input matInput formControlName="userName" type="text">
                        @if (model.get('userName')?.invalid) {
                            <mat-error>Informe o nome do cliente</mat-error>
                        }
                    </mat-form-field>

                    <p>Telefone de contato para o lojista receber o convite</p>
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone do cliente</mat-label>
                        <input matInput formControlName="userPhoneNumber" type="text" mask="(99)99999-9999">
                        @if (model.get('userPhoneNumber')?.invalid) {
                            <mat-error>Informe o telefone do cliente</mat-error>
                        }
                    </mat-form-field>
                    

                    <p>Email de contato para o lojista receber o convite</p>
                    <mat-form-field appearance="outline">
                        <mat-label>Email do cliente</mat-label>
                        <input matInput formControlName="userEmail" type="email">
                        @if (model.get('userEmail')?.invalid) {
                            <mat-error>Informe o email do cliente</mat-error>
                        }
                    </mat-form-field>
                    

                    <button type="submit" mat-flat-button class="btn-primary button-invite">
                        @if (_isLoading == true) {
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                        }
                        @else{
                            Convidar para sua rede de lojista
                        }
                    </button>
                    <button type="submit" mat-flat-button class="btn-secundary button-invite" style="margin-top: 15px;" (click)="openSelectBusinessSotre()">
                        Voltar
                    </button>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="vertical-aling-out">
                        <div class="vertical-aling-inner text-center">
                            <img class="image-ilustration" src="assets/images/invite-business-store.svg">
                        </div>
                    </div>
                   
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>


<mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="" >Fechar</button>  
</mat-dialog-actions>