import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConsultorStore } from '../../../../shared/services/API/orchestator-checkout/consultor-store.service';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { ConsultorStoreResponse } from '../../../../shared/services/responses/orchestrator-checkout/consultor-store.response';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-select-client',
  standalone: true,
  imports: [    
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatProgressSpinnerModule,
    MatIconModule,
    MatProgressBarModule, 
    CommonModule],
  templateUrl: './modal-select-business-store.component.html',
  styleUrl: './modal-select-business-store.component.css'
})
export class ModalSelectBusinessStoreComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, 
    private consultorStore:ConsultorStore,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ModalSelectBusinessStoreComponent>
  ){

  }

  public model: FormGroup;
  public _consultorStoreResponse:ConsultorStoreResponse;
  public _isLoading = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      seach: ['']
    });

    this.searchClient();
  }

  searchClient(){
    this._isLoading = true;
    this.consultorStore.get().subscribe({
      
      next:(consultorStoreResponse:ConsultorStoreResponse)=>{
        this._isLoading = false;
        if (consultorStoreResponse.isError) {
          this.alertService.show("Erro inesperado", consultorStoreResponse.errorDescription, AlertType.error);
          return;
        }

        this._consultorStoreResponse = consultorStoreResponse;
      },
      error: (error)=>{
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  selectBusinessStore(idBusinessStore:number){
    let data = {
      openInviteBusinessSotre: false,
      idBusinessStore:idBusinessStore
    };
    this.dialogRef.close(data);
  }

  openInviteBusinessSotre(){
    let data = {
      openInviteBusinessSotre:true,
      idBusinessStore:null
    };
    this.dialogRef.close(data);
  }
}
