import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CheckoutItemComponent } from "./checkout-item/checkout-item.component";
import { CommonModule, NgClass } from '@angular/common';
import { CheckoutResponse } from '../../../../shared/services/responses/orchestrator-checkout/checkout.response';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingBoxComponent } from "../../../../shared/component/loading-box/loading-box.component";

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [MatButtonModule, CheckoutItemComponent, NgClass, MatProgressSpinnerModule, MatProgressBarModule, LoadingBoxComponent, CommonModule],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css'
})
export class CheckoutComponent {
  public showCheckoutItens:boolean = false;
  @Input() checkoutResponse:CheckoutResponse;
  @Output() deleteCheckoutItem =  new EventEmitter<any>()
  @Output() editCheckoutItem =  new EventEmitter<any>()
  @Output() closeCheckout =  new EventEmitter<any>()

  clickShowCheckoutItens(){
    this.showCheckoutItens = !this.showCheckoutItens;
  }

  clickDeleteCheckoutItem(event:any){
    this.deleteCheckoutItem.emit(event);
  } 

  cliclCloseCheckout(){
   this.closeCheckout.emit(); 
  }

  clickEditCheckoutItem(event:any){

    this.editCheckoutItem.emit(event);
  }
}
