import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ProductUserDetailsResponse } from '../../responses/orchestrator-checkout/product-user-details.response';


@Injectable({
  providedIn: 'root'
})
export class ProductService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public getProduct(idProduct:number, idCheckout:number): Observable<ProductUserDetailsResponse> {
    var url = `Product/idProduct/${idProduct}`;
    if(idCheckout){
      url += `/idCheckout/${idCheckout}`;
    }
    return this.httpClient.get<ProductUserDetailsResponse>(environment.urlApiOrchestratorCheckout + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }   
}
