import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-action-nav',
  standalone: true,
  imports: [ 
    CommonModule,
    RouterModule
  ],
  templateUrl: './action-nav.component.html',
  styleUrl: './action-nav.component.css'
})
export class ActionNavComponent {
  @Input() showActionNav:boolean;
  @Output() showActionNavFalse =  new EventEmitter<number>()

  constructor(){

  }
  
  clickContainerOut(){
    this.showActionNavFalse.emit();
  }
}
