import { Component, EventEmitter, Input, Output, output } from '@angular/core';
import { ProductCheckoutStruct } from '../../../../shared/services/structs/orchestrator-checkout/product-checkout.struct';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-checkout-item-resume',
  standalone: true,
  imports: [MatProgressSpinnerModule, MatIconModule, MatButtonModule, CommonModule],
  templateUrl: './checkout-item-resume.component.html',
  styleUrl: './checkout-item-resume.component.css'
})
export class CheckoutItemResumeComponent {
  @Input() productCheckoutStruct:ProductCheckoutStruct;
  @Input() businessFactoryName:string;
  @Output() deleteCheckoutItem =  new EventEmitter<any>()

  clickDeleteCheckoutItem(idProductCheckout:number, idCheckout:number){
    if(this.productCheckoutStruct.isLoading)
      return;

    this.productCheckoutStruct.isLoading = true;
    
    let value = {
      idProductCheckout: idProductCheckout,
      idCheckout: idCheckout
    }
      
    this.deleteCheckoutItem.emit(value);
  }
}
