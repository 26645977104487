
@if(productResponseStruct == null || productResponseStruct == undefined){ 
    <div class="search-item text-center">
        <div class="item-images">
            <app-loading-box [height]="'371'" [width]="'300'" [margin]="'auto'" [display]="'block'"></app-loading-box>
        </div>
    
        <app-loading-box [height]="'20'" [width]="'150'" [margin]="'auto'" [display]="'block'" [marginTop]="'15'"></app-loading-box>
        <app-loading-box [height]="'15'" [width]="'210'" [margin]="'auto'" [display]="'block'" [marginTop]="'10'"></app-loading-box>
    
        <app-loading-box [height]="'20'" [width]="'110'" [margin]="'auto'"  [display]="'block'" [marginTop]="'20'"></app-loading-box>
        <app-loading-box [height]="'48'" [width]="'130'" [margin]="'auto'"  [display]="'block'" [marginTop]="'20'"></app-loading-box>
    </div>
} 


@if(productResponseStruct != null && productResponseStruct != undefined){
    <div class="search-item">
        <div class="item-images">
            @if(productResponseStruct.listProductImage != null && productResponseStruct.listProductImage.length > 0){
                <img src="{{productResponseStruct.listProductImage[0].url}}"  />
            }
            @if(productResponseStruct.listProductImage == null || productResponseStruct.listProductImage.length == 0){
                <img src="assets/images/clothing-default-2.jpg" />
            }
        </div>
    
        <label class="item-business">DBZ</label>
        <label class="item-name">{{productResponseStruct.productName}}</label>
    
        @if(productResponseStruct.listProductGridSizeStruct.length > 0){
            <div class="mini-grid">
                @for (item of productResponseStruct.listProductGridSizeStruct; track $index) {
                    @if(item.isAvaliable){
                        <span class="active">{{item.productGridSizeName}}</span>
                    }
                    @if(!item.isAvaliable){
                        <span >{{item.productGridSizeName}}</span>
                    }
                }
            </div>
        }
       
        <label class="item-price">{{productResponseStruct.basePrice | currency: 'BRL' }}</label>
        <button mat-flat-button type="button" class="btn-primary btn-add" (click)="btnClickAddItem(productResponseStruct.idProduct)">
            Adicionar
        </button>
    </div>
}
