import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from '../../structs/return.struct';
import { CheckoutProductRequest } from '../../requests/orchestator-checkout/checkout-product.request';
import { CheckoutResponse } from '../../responses/orchestrator-checkout/checkout.response';
import { ConsultorStoreResponse } from '../../responses/orchestrator-checkout/consultor-store.response';


@Injectable({
  providedIn: 'root'
})
export class ConsultorStore extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<ConsultorStoreResponse> {
    return this.httpClient.get<ConsultorStoreResponse>(environment.urlApiOrchestratorCheckout + "ConsultorStore", this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }    
}
