<h2 mat-dialog-title>Alterar o endereço deste pedido</h2>

<form class="form" [formGroup]="model"  (ngSubmit)="post()">
    <mat-dialog-content>
        <p>Lembre-se: a alteração do endereço será realizado apenas para a entrega deste pedido deste lojista.</p>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>CEP</mat-label>
                    <input matInput formControlName="zipcode" type="text" mask="99999-999" (focusout)="searchAddressCEP()">
                    @if (model.get('zipcode')?.invalid) {
                        <mat-error>Informe o CEP</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Rua</mat-label>
                    <input matInput formControlName="street" type="text">
                    @if (model.get('street')?.invalid) {
                        <mat-error>Informe a rua</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Numero</mat-label>
                    <input matInput formControlName="addressNumber" type="text">
                    @if (model.get('addressNumber')?.invalid) {
                        <mat-error>Informe o numero</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Complemento</mat-label>
                    <input matInput formControlName="complement" type="text">
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Bairro</mat-label>
                    <input matInput formControlName="neighborhood" type="text">
                    @if (model.get('neighborhood')?.invalid) {
                        <mat-error>Informe o bairro</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Cidade</mat-label>
                    <input matInput formControlName="city" type="text">
                    @if (model.get('city')?.invalid) {
                        <mat-error>Informe a cidade</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Estado</mat-label>
                    <input matInput formControlName="state" type="text">
                    @if (model.get('state')?.invalid) {
                        <mat-error>Informe o estado</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>
        
    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="submit" mat-flat-button class="btn-primary button-big">
            @if (isLoading == true) {
                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
            }
            @else{
                Salvar
            }
        </button>
    </mat-dialog-actions>
</form>